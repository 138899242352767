import React from 'react'

import ContentContainer from '../UI/ContentContainer'
import TopicDescAndImg from '../UI/TopicDescAndImg'


const S1AppDetails = () => {

  const handleClick = () => document.getElementById('registerToInvest').scrollIntoView()

  return (
		<div id="learnMore">	
			<ContentContainer background="var(--base-gray)">
				<TopicDescAndImg categoryQuery="SocietyOneApp" handleClick={handleClick}/>
			</ContentContainer>
		</div>
  )
}


export default S1AppDetails