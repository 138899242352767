import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby-link'

import ContentContainer from '../UI/ContentContainer'
import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton'

const S1AppFAQ = () => {

  return (
    <ContentContainer
      maxWidth="1020px"
    >
      <S1AppFAQWrapper>
        <div>
          <div style={{ marginBottom: 40 }}>
            <h2 className="text-center">Frequently Asked Questions</h2>
            <p className="text-center">Get answers to all your personal finance questions.</p>
          </div>
        </div>
        <HomeButton
          height={BUTTON_SIZE.HEIGHT_L}
          padding={BUTTON_SIZE.PADDING_L}
          fontSize={BUTTON_SIZE.FONT_SIZE_L}
          background="var(--base-navy)"
          color={BUTTON_COLOR.TEXT_COLOR_WHITE}
          text="View FAQs"
          handleClick={() => navigate('/marketplace/help-spendsave/')}
        />
      </S1AppFAQWrapper>
    </ContentContainer>
  )
}

const S1AppFAQWrapper = styled.div`
  button {
    margin-top: 40px;
  }
`

export default S1AppFAQ
