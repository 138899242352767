import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import ContentContainer from '../UI/ContentContainer'

const S1AppTandC = () => {

  const data = useStaticQuery(graphql`
    query S1TnCPageQuery {
        allContentfulGenericHeadingTextAndIcon(filter: {contentIdentifier: {eq: "S1AppTermsAndConditions"}}) {
          nodes {
            content {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }        
    `)

  const pageData = data.allContentfulGenericHeadingTextAndIcon.nodes[0];

  return (
    <ContentContainer
      padding="0 0 80px 0"
      mPadding="80px 24px"
    >
      <S1AppTandCWrapper id="s1app-TandC">
      <div
        dangerouslySetInnerHTML={{
          __html: pageData.content.childMarkdownRemark.html,
        }}
      />
      </S1AppTandCWrapper>
    </ContentContainer>
  )
}

const S1AppTandCWrapper = styled.div`
  * {
    font-size: 14px;
  }
  line-height: 1.5;
  a {
    text-decoration: underline;
  }
  a:hover {
    color: inherit;
  }
`

export default S1AppTandC
