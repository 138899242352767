import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import ContentContainer from '../UI/ContentContainer'
import AppStoreLogo from '../../assets/images/svg/app-store-logo.svg'
import GooglePlayLogo from '../../assets/images/svg/google-play-logo.svg'

const S1AppHero = () => {

  return (
    <ContentContainer
      padding="80px 0"
      mPadding="80px 24px"
    >
      <S1AppHeroWrapper>
        <div className='heroTxt'>
          <h1>Download the SocietyOne App</h1>
          <p>
            Making managing your finances easier, wherever, whenever.
          </p>
          
          <div className="flex-row">
            <a
              className="logo"
              rel="noopener noreferrer"
              target="_blank"
              href="https://apps.apple.com/au/app/societyone/id1532681841"
            >
              <AppStoreLogo className="svg" />
            </a>
            <a
              style={{ marginLeft: 20 }}
              className="logo"
              rel="noopener noreferrer"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.societyone.app"
            >
              <GooglePlayLogo className="svg" />
            </a>
          </div>
        </div>
        <div className="heroImg">
          <StaticImage
            src="../../assets/images/spendone-saveone-hero.png"
            alt="spendone-saveone-hero-image"
            placeholder='none'
          />
        </div>
      </S1AppHeroWrapper>
      <div style={{marginTop: 80}}>
     
      </div>
    </ContentContainer>
  )
}

const S1AppHeroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  p {
    font-size: 20px;
  }
  button {
    margin: 40px 0 80px 0;
  }
  h1 {
    margin-bottom: 24px;
  }
  .logo {
    height: 50px;
    width: auto;
  }
  .svg {
    height: 100%;
    width: auto;
  }
  .heroImg {
    width: 100%;
    max-width: 400px;
  }
  .heroTxt {
    width: 100%;
    max-width: 600px;
  }
  .heroTxt p{
    max-width: 400px;
  }
  .flex-row{
    padding-top: 60px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    button {
      margin: 50px auto;
    }
    .flex-row {
      margin-bottom: 80px;
    }
    .logo {
      margin-left: auto;
      margin-right: auto;
    }
  }
`

export default S1AppHero
