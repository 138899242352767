import React from 'react'
import Layout from '../layouts/layout'
import Seo from '../components/seo'
import S1AppDetails from '../components/ForS1AppPage/S1AppDetails'
import S1AppHero from '../components/ForS1AppPage/S1AppHero'
import AppQRCode from '../components/UI/AppQRCode'
import S1AppFAQ from '../components/ForS1AppPage/S1AppFAQ'
import S1AppTandC from '../components/ForS1AppPage/S1AppTandC'
import AwardListInHeader from '../components/blocks/AwardListInHeader'

const S1App = ({ path }) => {

  return (
    <Layout>
      <Seo title="Meet the SocietyOne App" path={path} description="Our app is here to help make managing your finances easier, wherever, whenever. Download the SocietyOne app today."/>
      <S1AppHero />
      <AwardListInHeader />
      <S1AppDetails />
      <AppQRCode apple google />
      <S1AppFAQ /> 
      <S1AppTandC />     
    </Layout>
  )
}


export default S1App
